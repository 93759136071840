var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{staticClass:"mb-1",attrs:{"clearable":false,"label":"formatted_address","placeholder":"Cari Alamat KFS","options":_vm.places,"reduce":function (option) { return option; }},on:{"search":_vm.debounceSearch,"input":_vm.setAddressInfo},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Alamat tidak ditemukan ")]}}])}),_c('GmapMap',{staticStyle:{"width":"100%","height":"350px"},attrs:{"center":_vm.center,"zoom":16,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
    }},on:{"click":_vm.updateCoordinates}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"dragend":_vm.updateCoordinates,"click":function($event){_vm.center=m.position}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }