<template>
  <validation-observer ref="formKFS">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row>
            <b-col sm="12">
              <google-map
                :address="masters.address"
                :has-lat-lng="latLng"
                @set="setAddress"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              class="order-1 order-sm-0"
              sm="7"
            >
              <!-- Alamat KFS -->
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="address"
                  >Alamat KFS</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Alamat KFS"
                    rules="required"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="models.address"
                      rows="4"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!--  -->

              <!-- Nama KFS -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Nama KFS</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama KFS"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.name"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!--  -->

              <!-- No Telepon -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="phone"
                  >No Telepon</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="No telepon"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="phone"
                      v-model="models.phone"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!--  -->

              <!-- Owner -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="owner"
                  >Owner</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Owner"
                    rules="required"
                  >
                    <b-form-select
                      id="owner"
                      v-model="models.accountId"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.owners"
                      value-field="profile.accountId"
                      class="w-100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!--  -->

              <!-- Foto KFS -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                  >Foto KFS</label>
                </b-col>
                <b-col sm="4">
                  <div class="image-input">
                    <validation-provider
                      #default="{ errors }"
                      name="Foto KFS"
                      :rules="vars.rulesImage"
                    >
                      <b-img
                        :src="getUrlOfFile(vars.photo, true)"
                        class="mb-2"
                      />
                      <label
                        for="file-input"
                      >
                        <input
                          id="file-input"
                          type="file"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          hidden
                          @change="AddPhoto"
                        >
                        <feather-icon icon="PlusIcon" />
                        Upload Photo
                      </label>
                      <input
                        v-model="vars.photo"
                        type="hidden"
                        :state="errors.length > 0 ? false:null"
                      >
                      <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                    </validation-provider>

                  </div>
                </b-col>
              </b-row>
              <!--  -->

              <!-- Tag -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="tag"
                  >Tag</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Tag"
                    rules="required"
                  >
                    <v-select
                      id="tag"
                      v-model="models.since"
                      :clearable="false"
                      label="name"
                      placeholder="- Cari Tag -"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.regencies"
                      :reduce="option => option"
                      @search="debounceSearch"
                      @input="setTagInfo"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Tidak ditemukan hasil pencarian
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!--  -->

              <!-- Level KFS -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="level-kfs"
                  >Level KFS</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Level KFS"
                    rules="required"
                  >
                    <b-form-select
                      id="level-kfs"
                      v-model="models.level"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.levels"
                      value-field="id"
                      text-field="name"
                      class="w-100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!--  -->

              <!-- Status -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="9">
                  <div class="col-sm-7 d-flex justify-content-between">
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <!--  -->

            </b-col>
            <b-col
              sm="5"
              class="order-0 order-sm-1"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Longitude</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    id="Name"
                    v-model="models.long"
                    disabled
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Latitude</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    id="Name"
                    v-model="models.lat"
                    disabled
                    type="text"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">

            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BFormSelect, BButton, BFormInput, BFormTextarea, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GoogleMap from '@/layouts/components/GoogleMap.vue'
import { uploadFile, getUrlOfFile } from '@/utils/helpers'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric } from '@validations'

export default {
  name: 'FormKFS',
  metaInfo: {
    title: 'Form KFS',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BFormInput,
    BFormTextarea,
    BImg,

    // Component
    GoogleMap,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        accountId: '',
        name: '',
        address: '',
        lat: '',
        long: '',
        phone: '',
        status: true,
        since: '',
        level: '',
      },
      vars: {
        rulesImage: 'required|image',
        photo: null,
      },
      masters: {
        regencies: [],
        levels: [
          {
            name: '- Pilih Level KFS -',
            id: '',
          },
        ],
        owners: [],
      },
      required,
      email,
      numeric,
      latLng: {},
      getUrlOfFile,
    }
  },
  watch: {
    imageField() {
      if (typeof this.vars.photo !== 'string') {
        this.vars.rulesImage = 'required|image'
      }
    },
  },
  created() {
    this.getListOption()
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.kfs}/${this.$route.params.id}`)
          .then(res => {
            this.vars.rulesImage = 'required'
            const { data } = res.data
            this.models.accountId = data.accountId
            this.models.name = data.name
            this.models.address = data.address
            this.models.lat = data.lat
            this.models.long = data.long
            this.models.phone = data.phone
            this.models.status = data.status
            this.models.since = data.since
            this.models.level = data.level

            this.vars.photo = data.photo
            if (data.lat && data.long) {
              this.latLng = {
                lat: parseFloat(data.lat),
                lng: parseFloat(data.long),
              }
            }
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    getListOption() {
      const getOwners = this.$axios.get(`${api.list_owner}`)
      const getLevelKFS = this.$axios.get(`${api.level}`)
      const getRegencies = this.$axios.get(`${api.list_regency}`)

      Promise.all([getOwners, getLevelKFS, getRegencies]).then(res => {
        const [owners, levels, regencies] = res
        owners.data.data.forEach(data => {
          data.text = data.profile.fullName
          this.masters.owners.push(data)
        })

        this.masters.levels = [...levels.data.data.rows]
        this.masters.regencies = [...regencies.data.data.rows]
      })
    },
    setAddress(data) {
      const { address, lat, lng } = data
      this.models.lat = lat.toString()
      this.models.long = lng.toString()
      if (address) {
        this.models.address = address
      }
    },
    setTagInfo(data) {
      this.models.since = data.name
    },
    debounceSearch(value) {
      if (value !== '') {
        this.models.since = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag()
          this.masters.regencies = [...response]
        }, 500)
      }
    },
    async handleSearchTag() {
      const res = await this.$axios.get(`${api.list_regency}?search=${this.models.since}`)
      return res.data.data.rows
    },
    AddPhoto(event) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      if (event.target.files[0].size < fileSizeLimit) {
        const photo = event.target.files[0]
        this.vars.photo = photo
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    save() {
      this.$refs.formKFS.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          this.models.photo = typeof this.vars.photo === 'string' ? this.vars.photo : await uploadFile(this.vars.photo)

          if (!this.$route.params.id) {
            request = this.$axios.post(api.kfs, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.kfs}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} kfs`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
