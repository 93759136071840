<template>
  <div>
    <v-select
      class="mb-1"
      :clearable="false"
      label="formatted_address"
      placeholder="Cari Alamat KFS"
      :options="places"
      :reduce="option => option"
      @search="debounceSearch"
      @input="setAddressInfo"
    >
      <template #no-options="{ search, searching, loading }">
        Alamat tidak ditemukan
      </template>
    </v-select>
    <GmapMap
      :center="center"
      :zoom="16"
      style="width: 100%; height: 350px"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
      }"
      @click="updateCoordinates"
    >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @dragend="updateCoordinates"
        @click="center=m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  name: 'GoogleMapsComponent',
  components: {
    vSelect,
  },
  props: {
    hasLatLng: Object,
  },
  data() {
    return {
      center: { lat: 20, lng: 20 },
      markers: [{ position: { lat: 20, lng: 20 } }],
      currentPlace: null,
      query: '',
      places: [],
    }
  },
  watch: {
    hasLatLng() {
      if (Object.keys(this.hasLatLng).length > 0) {
        this.markers[0].position = this.hasLatLng
        this.center = this.hasLatLng
      }
    },
  },
  mounted() {
    this.geolocate()
  },
  methods: {
    async updateCoordinates(location, manual) {
      const lat = manual ? location.geometry.location.lat : location.latLng.lat()
      const lng = manual ? location.geometry.location.lng : location.latLng.lng()

      const data = {
        lat,
        lng,
      }

      this.markers[0].position = data

      if (manual) {
        this.center = data
        data.address = location.formatted_address
      } else {
        const [address] = await this.handleSearch(`${lat},${lng}`)
        data.address = address.formatted_address
      }

      this.$emit('set', data)
    },

    debounceSearch(value) {
      if (value !== '') {
        this.query = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearch()
          this.places = response
        }, 300)
      }
    },
    async handleSearch(latlng) {
      let request = ''
      if (latlng) {
        request = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${process.env.VUE_APP_GOOGLE_MAPS_API}`
      } else {
        request = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.query}&key=${process.env.VUE_APP_GOOGLE_MAPS_API}`
      }
      const res = await axios.get(request)
      return res.data.results
    },
    setAddressInfo(address) {
      // Set coordinate google maps
      this.updateCoordinates(address, true)
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        this.markers[0].position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    },
  },
}
</script>

<style>

</style>
